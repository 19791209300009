
































































































































import ICrudClient from "@/lib/ICrudClient";
import DataProvider from "@/lib/DataProvider";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import FormContainer from "../FormContainer.vue";
import Loader from "../Loader.vue";
import DynamicForm from "@/components/DynamicForm.vue";
import EntitySelect from "@/components/EntitySelect.vue";
import { PrescreeningActionTaken } from "@/data/models/PrescreeningActionTaken";
import { Project } from "@/data/models/Projects";
import {
  Prescreening,
  Questionnaire,
  QuestionnaireSubmission
} from "@/data/models/Questionnaires";
import { User } from "@/data/models/Users";
import { Guid } from "guid-typescript";
import { HouseholdMember } from "@/data/models/HouseholdMember";
import { warn } from "vue-class-component/lib/util";
import GoogleMap from "@/components/GoogleMap.vue";

@Component({
  components: {
    FormContainer,
    Loader,
    DynamicForm,
    EntitySelect,
    GoogleMap
  }
})
export default class PrescreeningResults extends Vue {
  @Prop()
  public id!: string;

  @Prop({ default: false })
  public close!: boolean;

  private item: any = null;
  private answers: any[] = [];

  private householdMember: any = null;
  private deleteDialog = false;
  private deleting = false;

  async deletePrescreening() {
    this.deleting = true;
    try {
      await this.$service.crud.prescreenings.deleteAsync([this.item.id]);
      this.$router.push({
        path: "/prescreenings/"
      });
    } catch (e) {
      console.error(e);
    } finally {
      this.deleting = false;
    }
  }
  async assignUser() {
    try {
      this.item.assignedDate = new Date().toISOString();
      await this.$service.crud.prescreenings.saveAsync(this.item);
      this.$q.notify({
        message: "Item saved successfully!",
        color: "green-4",
        timeout: 2000
      });
    } catch (e) {
      console.error(e);
    }
  }

  async initialize() {
    if (this.id) {
      const item = await this.$service.providers.prescreenings.fetchItemAsync(
        this.id
      );
      this.answers = item.questionnaireSubmission.answers;
      this.item = item;
      const householdMembers = await this.$service.providers.householdMembers.fetchItemsAsync(
        {
          columnFilters: [
            {
              column: "PrescreeningId",
              value: `${this.item.id}`,
              op: "Equals"
            }
          ]
        }
      );
      if (householdMembers.items && householdMembers.items.length > 0) {
        this.householdMember = householdMembers.items[0];
      }
    }
  }
}
