

















import { Component, Prop, Vue, Watch } from "vue-property-decorator";

@Component({
  components: {}
})
export default class GoogleMap extends Vue {
  @Prop()
  public item!: any;

  @Prop()
  public name!: string;

  @Prop()
  public width!: string;

  @Prop()
  public height!: string;

  @Prop()
  public isView!: boolean;

  private currentPosition: any = null;
  private canClick: any = true;

  private center: any = null;

  placeMarker(e: any) {
    if (!this.canClick || this.isView) {
      return;
    }

    this.canClick = false;
    this.currentPosition = {
      lat: e.latLng.lat(),
      lng: e.latLng.lng()
    };
    this.emitPosition(this.currentPosition);
    setTimeout(() => (this.canClick = true), 2000);
  }

  mounted() {
    this.getLocation();
  }

  getLocation() {
    if (this.item && this.item.mapLat && this.item.mapLong) {
      if (this.item.mapLat && this.item.mapLong) {
        this.center = {
          lat: this.item.mapLat,
          lng: this.item.mapLong
        };
        this.currentPosition = this.center;
      } else {
        this.getCurrentLocation();
      }
    } else {
      this.getCurrentLocation();
    }
  }

  getCurrentLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        position => {
          this.center = {
            lat: position.coords.latitude,
            lng: position.coords.longitude
          };
          this.emitPosition(this.center);
        },
        e => {
          console.log(e.message);
        },
        { enableHighAccuracy: true }
      );
    }
  }

  emitPosition(position: any) {
    this.$emit("position", position);
  }
}
